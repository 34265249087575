// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  position: relative;
  padding-bottom: 35%;
  height: 0;
  overflow: hidden;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media screen and (max-width: 800px) {
  .map-container {
    padding-bottom: 75%;
    position: relative;
    height: 0;
    overflow: hidden;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/map/Map.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,SAAS;AACX;;AAEA;EACE;IACE,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,gBAAgB;EAClB;AACF","sourcesContent":[".map-container {\n  position: relative;\n  padding-bottom: 35%;\n  height: 0;\n  overflow: hidden;\n}\n\n.map-container iframe {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border: 0;\n}\n\n@media screen and (max-width: 800px) {\n  .map-container {\n    padding-bottom: 75%;\n    position: relative;\n    height: 0;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
