import React from "react";
import "./ServiceSection.css";

export default function ServiceSection(props) {
  return (
    <div className="container">
      <div className="content" dangerouslySetInnerHTML={{ __html: props.htmlContent }}/>
    </div>
  );
}
