import React from "react";
import Footer from "../footer/Footer";
import ContactSection from "../contactSection/ContactSection";
import Map from "../map/Map";

function Kontakt() {
  return (
    <>
      <ContactSection />
      <Map />
      <Footer />
    </>
  );
}

export default Kontakt;
