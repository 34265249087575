import React from "react";
import "./Section2.css";


function Section2(props) {
  return (
    <div className="section2-container">
      <section className="section2-subscription">
        <p className="section2-subscription-heading">{props.heading}</p>
        <div className="section2-content">
          <p className="section2-subscription-text">{props.text}</p>
          <img src={props.img} alt="deal" className="section2-image" />
        </div>
        <br />
      </section>
    </div>
  );
}

export default Section2;
