// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2b2a28;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

#naslov{
  color:#ccaf5a;
}

.lista {
  list-style: none;
}

.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #fefefe;
}

a:hover {
  color: #ccaf5a;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.sdz{
  position: relative;
  height: 100px;
  width: 250px;
  z-index: 5;
  border-radius: 2px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;oCACkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".footer-container {\n  padding: 4rem 0 2rem 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-color: #2b2a28;\n}\n\n.footer-subscription {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  margin-bottom: 24px;\n  padding: 24px;\n  color: #fff;\n}\n\n#naslov{\n  color:#ccaf5a;\n}\n\n.lista {\n  list-style: none;\n}\n\n.footer-subscription > p {\n  font-family: \"Trebuchet MS\", \"Lucida Sans Unicode\", \"Lucida Grande\",\n    \"Lucida Sans\", Arial, sans-serif;\n}\n\n.footer-subscription-heading {\n  margin-bottom: 24px;\n  font-size: 24px;\n}\n\n.footer-subscription-text {\n  margin-bottom: 24px;\n  font-size: 20px;\n}\n\na {\n  text-decoration: none;\n  color: #fefefe;\n}\n\na:hover {\n  color: #ccaf5a;\n}\n\n.website-rights {\n  color: #fff;\n  margin-bottom: 16px;\n}\n\n.sdz{\n  position: relative;\n  height: 100px;\n  width: 250px;\n  z-index: 5;\n  border-radius: 2px;\n}\n\n@media screen and (max-width: 820px) {\n  .footer-links {\n    padding-top: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
