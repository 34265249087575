import React from "react";
import "./Parts.css";
import { Link } from "react-router-dom";

function Section(props) {
  return (
    <>
      <li className="parts__item">
        <Link className="parts__item__link" to={props.path}>
          <div className="parts__item__info">
            <h2 id="naslov2">{props.label}</h2>
            <h5 className="parts__item__text">{props.text}</h5>
          </div>
          <button className="section__btn">Više ⇨</button>
        </Link>
      </li>
    </>
  );
}

export default Section;
