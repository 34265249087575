import React from "react";
import "./Footer.css";
import sdz from "../../img/sdz.jpg";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-heading">
          KNJIGOVODSTVO | RAČUNOVODSTVO | PISANJE POSLOVNOG
          PLANA | ADMINISTRATIVNI POSLOVI
        </p>
        <p className="footer-subscription-text">
          3. Imotske bojne 3, 21260 IMOTSKI
        </p>
        <div className="footerlink-items">
          <h2 id="naslov">Kontakt</h2>
          <br />
          <ul className="lista">
            <li>Mob : 099 737 2264 </li>
            <li>
              Email :{" "}
              <a href="mailto:divic.josipa@gmail.com">divic.josipa@gmail.com</a>
            </li>
          </ul>
        </div>
        <p>Srdačan pozdrav! LIBRUM obrt za računovodstvene usluge</p>
        <br />
        <div className="suf">
          <img src={sdz} alt="Splitsko-dalmatinska županija" className="sdz" />
          <p>Sufinancirala Splitsko-dalmatinska županija</p>
        </div>
        <br />
        <small className="website-rights">
          © LIBRUM 2023. | Sva prava pridržana.
        </small>
      </section>
    </div>
  );
}

export default Footer;
