// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #fefefe;
}

.contact {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin-top: 10rem;
  /* z-index: 98; */
}

.contact-title {
  color: #2b2a28;
  text-align: center;
  padding-top: 3%;
  margin-bottom: 60px;
  font-weight: 600;
  position: relative;
}

.wrapper {
  display: flex;
  gap: 5rem;
  margin-top: 7rem;
  justify-content: space-evenly;
  position: relative;
}

.wrapper::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 55%;
  background-color: #ccaf5a;
  left: 51%;
  top: 34%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 900px) {
  .wrapper {
    flex-direction: column;
  }

  .wrapper::after {
    display: none;
  }

  .left,
  .right {
    max-width: 100%;
    padding: 3rem 2rem 2rem 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/contactSection/ContactSection.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,wBAAmB;EAAnB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,SAAS;EACT,QAAQ;EACR,gCAAgC;AAClC;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf;;EAEA;;IAEE,eAAe;IACf,4BAA4B;EAC9B;AACF","sourcesContent":["body {\n  background-color: #fefefe;\n}\n\n.contact {\n  height: fit-content;\n  width: 100%;\n  margin-top: 10rem;\n  /* z-index: 98; */\n}\n\n.contact-title {\n  color: #2b2a28;\n  text-align: center;\n  padding-top: 3%;\n  margin-bottom: 60px;\n  font-weight: 600;\n  position: relative;\n}\n\n.wrapper {\n  display: flex;\n  gap: 5rem;\n  margin-top: 7rem;\n  justify-content: space-evenly;\n  position: relative;\n}\n\n.wrapper::after {\n  position: absolute;\n  content: \"\";\n  width: 3px;\n  height: 55%;\n  background-color: #ccaf5a;\n  left: 51%;\n  top: 34%;\n  transform: translate(-50%, -50%);\n}\n\n@media screen and (max-width: 900px) {\n  .wrapper {\n    flex-direction: column;\n  }\n\n  .wrapper::after {\n    display: none;\n  }\n\n  .left,\n  .right {\n    max-width: 100%;\n    padding: 3rem 2rem 2rem 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
