import React from "react";
import "./Map.css";

export default function Map() {
  return (
    <div className="gold">
      <div className="map-container">
        <iframe
          title="Google maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2896.679945791922!2d17.214658712250294!3d43.44639997099224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134adffe2d055a89%3A0x38d8ea20c5dbb603!2sKnjigovodstveni%20servis%20Librum!5e0!3m2!1sen!2sba!4v1695972576980!5m2!1sen!2sba"
          width="1800"
          height="400"
          style={{border:'0'}}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        
      </div>
    </div>
  );
}
