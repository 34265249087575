import React from "react";
import "./Section3.css";


function Section3(props) {
  return (
    <div className="Section3-container">
      <section className="Section3-subscription">
        <p className="Section3-subscription-heading">{props.heading}</p>
        <div className="Section3-content">
          <img src={props.img} alt="deal" className="Section3-image" />
          <p className="Section3-subscription-text">{props.text}</p>
        </div>
        <br />
      </section>
    </div>
  );
}

export default Section3;
