import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo2.jpg";
import { useState} from "react";
import './Navbar.css'

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="navbar">
      <div className="navbar-container"></div>
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="logo" />
      </Link>
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"} />
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
      <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            Početna
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/usluge" className="nav-links" onClick={closeMobileMenu}>
            Usluge
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/kontakt" className="nav-links" onClick={closeMobileMenu}>
            Kontakt
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
