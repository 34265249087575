import React from "react";
import Footer from "../footer/Footer";
import Section2 from "../section2/Section2";
import teamwork from "../../img/teamwork.jpg";
import ServiceSection from "../serviceSection/ServiceSection";
import adm from "../../img/slika.jpg";

function Usluge() {
  const htmlContent = `<h1 class="nasl">
  Računovodstveno-financijsko izvještavanje i analitika:
</h1>
<div class="row">
  <div class="service">
    <p> Vođenje poslovnih i pomoćnih knjiga</p>
  </div>
  <div class="service">
    <p> Vođenje osnovnih sredstava</p>
  </div>
  <div class="service">
    <p> Obračun plaća, honorara i ugovora o djelu</p>
  </div>
  <div class="service">
    <p> Obračun amortizacije</p>
  </div>
  <div class="service">
    <p> Analitika otvorenih stavaka, odnos kupaca i dobavljača</p>
  </div>
  <div class="service">
    <p>
       Vođenje blagajne - isplatnice, uplatnice i blagajnički izvještaji
    </p>
  </div>
  <div class="service">
    <p> Izrada računa</p>
  </div>
  <div class="service">
    <p> Vođenje robnog i materijalnog knjigovodstva</p>
  </div>
  <div class="service">
    <p> Obračun putnih naloga</p>
  </div>
  
</div>`;

  const htmlContent2 = `
  <div class="cont"><h1 class="nasl">
    Mjesečni i godišnji izvještaji:
  </h1>
  <div class="row">
    <div class="service" class="au">
      <p> Popunjavanje izvještaja za potrebe državnih institucija</p>
    </div>
    <div class="service" class="au">
      <p> Kreiranje poreznih evidencija i prijava</p>
    </div>
    <div class="service" class="au">
      <p> Obračun PDV-a</p>
    </div>
    <div class="service" class="au">
      <p> Izrada statističkih izvještaja</p>
    </div>
    <div class="service" class="au">
      <p>
         Izrada godišnjeg obračuna: račun dobiti i gubitka, bilanca, novčani
  tokovi, bilješke 
      </p>
    </div>
    <div class="service" class="au">
      <p> Obračun komorskog doprinosa, općekorisne funkcije šuma, raznih
  članarina</p>
  </div>
  </div>
  </div>`;

  const htmlContent3 = `<div class="cont"><h1 class="nasl">
  Naše administrativne usluge obuhvaćaju sljedeće:
</h1>
<div class="row">
  <div class="service">
    <p> Zaprimanje i arhiviranje dokumenata</p>
  </div>
  <div class="service" >
    <p>Priprema i plasiranje dokumentacije u poštanski ured ili tijela državne i
javne uprave</p>
  </div>
  <div class="service" >
    <p>Planiranje i organiziranje sastanaka </p>
  </div>
  <div class="service" >
    <p>Korespondencija s poslovnim partnerima, kupcima, dobavljačima </p>
  </div>
  <div class="service" >
    <p>Zaprimanje i prosljeđivanje pošte</p>
  </div>
  <div class="service" >
    <p>Pisanje ponuda prema uputama klijenta </p>
</div>
<div class="service" >
    <p>Fotokopiranje i skeniranje dokumenata</p>
  </div>
<div class="service" >
    <p>Evidentiranje ulaznih i izlaznih spisa</p>
  </div>
<div class="service" >
    <p>Naručivanje uredskog materijala po zahtjevu klijenta</p>
  </div>
  <div class="service" >
    <p>Prijepis i unos podataka</p>
  </div>
  <div class="service" >
    <p>Druge administrativne poslove po dogovoru</p>
  </div>
</div>
</div> `;

  const htmlContent4 = `<div class="cont"><h1 class="nasl">
Cjenik
</h1>
<div class="row">
<div class="service">
  <p>Paušalni obrtnici- 80 € </p>
</div>
<div class="service" >
  <p>Obrtnici - od 100 €</p>
</div>
<div class="service" >
  <p> Firme izvan sustava PDV-a - od 150 €</p>
</div>
<div class="service" >
  <p>Firme u sustavu pdv-a - od 200 €</p>
</div>
<div class="service" >
  <p>Ostale usluge - prema opsegu posla</p>
</div>
</div>
</div>`;

  const divStyle = {
    width: "100%",
    backgroundColor: "#fefefe",
    height: "5rem",
  };

  return (
    <>
      <div className="blank" style={divStyle}></div>
      <Section2
        heading="Računovodstvene usluge"
        text=" Kontinuitet i kvalitetu visoke razine usluga održavamo prije svega
neprekidnim ulaganjem u znanje u skladu sa čestim zakonskim
promjenama i digitalizacijom poslovanja, ali i stalnim fokusom na
individualni pristup, ljubaznost i razvijanje dobrih međuljudskih odnosa s
našim klijentima. U nastavku slijedi detaljniji pregled računovodstvenih usluga za trgovačka
društva"
        img={teamwork}
      />
      <ServiceSection htmlContent={htmlContent} />
      <ServiceSection htmlContent={htmlContent2} />
      <Section2
        heading="Administrativne usluge"
        text=" Pišemo poslovne planove za samozapošljavanje i zapošljavanje te
        projektnu dokumentaciju za županijske natječaje.
        Asistiramo i konzultiramo od inicijalne ideje pokretanja vlastitog posla,
        zajedno s klijentima kreiramo poslovne planove i pratimo ih u daljnjem
        poslovanju.
        Fizičkim i poslovnim subjektima nudimo profesionalnu uslugu u postupcima
        pred poreznom upravom i drugim državnim tijelima.
         "
        img={adm}
      />
      <ServiceSection htmlContent={htmlContent3} />
      <ServiceSection htmlContent={htmlContent4} />
      <Footer />
    </>
  );
}

export default Usluge;
