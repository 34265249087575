import "../../App.css";
import Footer from "../footer/Footer";
import Hero from "../hero/Hero";
import Parts from "../section/Parts";
import Section2 from "../section2/Section2";
import Section3 from "../section3/Section3";
import deal from "../../img/deal2.jpg";
import staff from "../../img/staff2.jpeg";

function Home() {
  return (
    <>
      <Hero />
      <Section2
        heading="Što očekivati od nas?"
        text=" Naša posvećenost zadovoljstvu klijenata je temeljna vrijednost našeg
            poslovanja.Klijenti mogu očekivati izvanrednu uslugu i najviši
            standard kvalitete od nas. Obećavamo brzu i efikasnu komunikaciju,
            transparentnost u svim fazama projekta, i pažljivu pažnju prema svim
            detaljima. Bez obzira na to jeste li već dugogodišnji partner ili
            novi klijent, svaki klijent je za nas izuzetno važan, i trudimo se
            da vaše iskustvo s nama bude iznimno i bez problema."
        img={deal}
      />
      <Section3
        heading="Stručno osoblje"
        text="Naša posvećenost uspjehu leži u 
            znanju i vještinama stečenim tijekom više od 20 godina rada u knjigovodstvenim
            poslovima, te razumijevanju klijenata i njihovih potreba.
            Posjedujemo ne samo impresivno znanje i vještine, već također dijelimo duboko razumijevanje i
            poštovanje prema našim klijentima."
        img={staff}
      />
      <Parts />
      <Footer />
    </>
  );
}

export default Home;
