import React from "react";
import { Button } from "../button/Button";
import "./Hero.css";
import "../../App.css"; //?
import img1 from "../../img/img2.jpg";
// import { Link } from "react-router-dom";

function Hero() {
  return (
    <div className="hero-container">
      <img src={img1} alt="Bookkeeping" />
      <h2 className="sastanak">Zakažite sastanak odmah!</h2>

      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--heroo"
          buttonSize="btn--large"
        >
          Kontakt
        </Button>
      </div>
    </div>
  );
}

export default Hero;
