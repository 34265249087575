import React from "react";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import ContactForm from "../contactForm/ContactForm";
import ContactInfoItem from "../contactInfoItem/ContactInfoItem";
import "./ContactSection.css";

export default function ContactSection() {
  return (
    <div className="contact-container">
      <div className="contact">
        <h1 className="contact-title">Kontaktirajte nas</h1>
        <div className="wrapper">
          <div className="left">
            <ContactInfoItem
              icon={<MdLocalPhone></MdLocalPhone>}
              text="+385 99 737 2264"
            />
            <ContactInfoItem
              icon={<MdEmail></MdEmail>}
              text="divic.josipa@gmail.com"
            />
            <ContactInfoItem text="Imotski, Hrvatska" />
          </div>
          <div className="right">
            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
    </div>
  );
}
