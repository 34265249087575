// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 8%;
  background-color: #ccaf5a;
  padding-bottom: 3rem;
}

.nasl {
  text-align: center;
  padding-top: 5%;
  margin-bottom: 60px;
  font-weight: 600;
  position: relative;
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
  padding: 0 15%;
}

.service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px 10px;
  border-radius: 15px;
  background-color: #fefefe;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.17);
  filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
}

.service:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/serviceSection/ServiceSection.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,2DAA2D;EAC3D,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,0CAA0C;EAE1C,oDAAoD;AACtD;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  width: 100%;\n  height: fit-content;\n  padding: 0 8%;\n  background-color: #ccaf5a;\n  padding-bottom: 3rem;\n}\n\n.nasl {\n  text-align: center;\n  padding-top: 5%;\n  margin-bottom: 60px;\n  font-weight: 600;\n  position: relative;\n}\n\n.row {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n  grid-gap: 30px;\n  padding: 0 15%;\n}\n\n.service {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  padding: 25px 10px;\n  border-radius: 15px;\n  background-color: #fefefe;\n  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.17);\n  -webkit-filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));\n  filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));\n}\n\n.service:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
