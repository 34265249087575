// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
  padding: 2rem;
  display: flex;
  gap:1rem;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 2rem;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}

.icon {
  color: #ccaf5a;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

svg {
  width: 3.5rem;
  height: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/contactInfoItem/ContactInfoItem.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,+CAA+C;EAE/C,yDAAyD;AAC3D;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".item {\n  padding: 2rem;\n  display: flex;\n  gap:1rem;\n  align-items: center;\n  border-radius: 20px;\n  margin-bottom: 2rem;\n  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);\n  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));\n  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));\n}\n\n.icon {\n  color: #ccaf5a;\n  padding: 1rem;\n  align-items: center;\n  justify-content: center;\n}\n\nsvg {\n  width: 3.5rem;\n  height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
