import React from "react";
import { MdPlace } from "react-icons/md";
import "./ContactInfoItem.css";

export default function ContactInfoItem({
  icon = <MdPlace></MdPlace>,
  text = "info info info",
}) {
  return (
    <div className="item">
      <div className="icon">{icon}</div>
      <div className="info">
        <p>{text}</p>
      </div>
    </div>
  );
}
