import React from "react";
import Section from "./Section";

function Parts() {
  return (
    <div className="parts">
      <h1 id="naslov">Saznajte više</h1>
      <div className="parts__container">
        <div className="parts__wrapper">
          <ul className="parts__items">
            <Section 
            text="Librum je knjigovodstveni servis nove generacije fokusiran na pružanje najboljih praksa i najviših standarda knjigovodstvenih usluga."
            label="O nama"
            path="/usluge"
            />
            <Section 
            text="Pronaći nas možete u uredu Librum u Imotskom na adresi 3. Imotske bojne 3 "
            label="Pronađite nas"
            path="/kontakt"
            />
            <Section 
            text="Bez obzira kakve usluge tražite, naša široka ponuda pruža visokokvalitetne opcije po pristupačnim cijenama "
            label="Cjenik"
            path="/usluge"
            />
            <Section 
            text="Vaša povratna informacija i pitanja su nam izuzetno važni. Spremni smo saslušati vas i pružiti potrebne odgovore."
            label="Kontakt"
            path="/usluge"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Parts;
