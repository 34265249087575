// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.sastanak {
  color: #fefefe;
  font-size: 45px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-top: 2rem;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .sastanak {
    font-size: 35px;
    margin-top: 4rem;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/hero/Hero.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iDAAiD;EACjD,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf;oCACkC;EAClC,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":["img {\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  z-index: -1;\n}\n\n.hero-container {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);\n  object-fit: contain;\n}\n\n.sastanak {\n  color: #fefefe;\n  font-size: 45px;\n  font-family: \"Trebuchet MS\", \"Lucida Sans Unicode\", \"Lucida Grande\",\n    \"Lucida Sans\", Arial, sans-serif;\n  margin-top: 2rem;\n}\n\n.hero-btns {\n  margin-top: 32px;\n}\n\n.hero-btns .btn {\n  margin: 6px;\n}\n\n.fa-play-circle {\n  margin-left: 4px;\n}\n\n@media screen and (max-width: 960px) {\n  .sastanak {\n    font-size: 35px;\n    margin-top: 4rem;\n    text-align: center;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .btn-mobile {\n    display: block;\n    text-decoration: none;\n  }\n\n  .btn {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
