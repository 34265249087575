// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form{
    width:100%;

}
.form-group{
    width:100%;
    margin-bottom: 2rem;
}
label{
    font-size: 1.3rem;
}
input , textarea{
    width:100%;
    font-size: 1rem;
    padding: 1.2rem;
    outline: none;
    border:none;
    border-radius: 10px;
    margin-top: 1rem;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}
textarea{
    min-height: 250px;
    resize:vertical;
}
.sub{
    background-color: white;
    height: 3rem;
    width: 100%;
    font-size: 1.3rem;
    display: inline-block;
    outline: none;
    border-radius: 8px;
    border:none;
    cursor: pointer;
    margin-bottom: 2rem;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}

.sub:hover{
  background-color:#ccaf5a;
  transition: 0.7s;
}`, "",{"version":3,"sources":["webpack://./src/components/contactForm/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,UAAU;;AAEd;AACA;IACI,UAAU;IACV,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,eAAe;IACf,eAAe;IACf,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,+CAA+C;EAEjD,yDAAyD;AAC3D;AACA;IACI,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,qBAAqB;IACrB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,+CAA+C;EAEjD,yDAAyD;AAC3D;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;AAClB","sourcesContent":["form{\n    width:100%;\n\n}\n.form-group{\n    width:100%;\n    margin-bottom: 2rem;\n}\nlabel{\n    font-size: 1.3rem;\n}\ninput , textarea{\n    width:100%;\n    font-size: 1rem;\n    padding: 1.2rem;\n    outline: none;\n    border:none;\n    border-radius: 10px;\n    margin-top: 1rem;\n    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);\n  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));\n  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));\n}\ntextarea{\n    min-height: 250px;\n    resize:vertical;\n}\n.sub{\n    background-color: white;\n    height: 3rem;\n    width: 100%;\n    font-size: 1.3rem;\n    display: inline-block;\n    outline: none;\n    border-radius: 8px;\n    border:none;\n    cursor: pointer;\n    margin-bottom: 2rem;\n    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);\n  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));\n  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));\n}\n\n.sub:hover{\n  background-color:#ccaf5a;\n  transition: 0.7s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
